<template>
  <div>
    <b-row>
      <b-col md="5" class="mx-auto">
        <b-card :title="$t('rewards.enterYourTransactionDetails')">
          <b-row>
            <b-col md="12">
              <b-alert show :variant="messageType" class="p-1">{{ message }}</b-alert>

              <b-form-input
                class="form-group"
                v-model="transactionHash"
                :placeholder="$t('rewards.transactionHash')"
                :disabled="isSubmitting"
              ></b-form-input>
              <b-form-input
                class="form-group"
                v-model="walletAddress"
                :placeholder="$t('rewards.walletAddress')"
                :disabled="isSubmitting"
              ></b-form-input>

              <b-button
                @click="submitRequest"
                :disabled="isSubmitting || transactionHash == '' || walletAddress == ''"
                variant="primary"
                block
                >{{
                  isSubmitting ? $t("rewards.pleaseWait") : $t("rewards.submitRequest")
                }}</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BFormInput, BCardHeader, BAlert, BButton } from "bootstrap-vue";
import axios from "axios";
export default {
  name: "RewardTransfer",
  data: () => {
    return {
      isSubmitting: false,
      transactionHash: "",
      walletAddress: "",
      message: "",
      messageType: "",
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BAlert,
    BButton,
  },
  methods: {
    submitRequest: async function () {
      this.isSubmitting = true;

      try {
        const formData = new FormData();
        formData.append("rewardTrx", this.transactionHash);
        formData.append("requestedWallet", this.walletAddress);
        const { data } = await axios.post("/user/updatewallet", formData);
        this.message = "Thank You, Your Request will be processed soon.";
        this.messageType = "success";
        this.walletAddress = "";
        this.transactionHash = "";
      } catch (e) {
        this.message = e.response.data.message;
        this.messageType = "danger";
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
